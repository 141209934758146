import { useEffect, useRef } from "react";
import { Group } from "three";
import gsap from "gsap";

/**
 * Custom hook to handle animations and references for a CMY cube scene.
 * This hook manages a 3D cube's pop-up and rotation animations using GSAP.
 *
 * @returns {Object} An object containing `meshRef` for the cube group.
 */
const useCMYCubeScene = () => {
  // Reference to the 3D group (cube mesh) in the Three.js scene
  const meshRef = useRef<Group>(null);

  // Reference to the GSAP timeline animation instance
  const animationRef = useRef<gsap.core.Timeline | null>(null);

  // Factor to control the overall speed of rotations
  const factor = 3;

  // Randomized rotation speed for each axis
  const rotationSpeedRef = useRef({
    x: (Math.random() * 0.5 + 0.5) * (Math.random() < 0.5 ? -1 : 1) * factor, // Random speed and direction for X-axis
    y: (Math.random() * 0.5 + 0.5) * (Math.random() < 0.5 ? -1 : 1) * factor, // Random speed and direction for Y-axis
    z: (Math.random() * 0.5 + 0.5) * (Math.random() < 0.5 ? -1 : 1) * factor, // Random speed and direction for Z-axis
  });

  useEffect(() => {
    /**
     * Starts the GSAP animations for the 3D cube.
     * - Performs a pop-up scaling animation.
     * - Applies continuous rotation to the cube.
     */
    const startAnimation = () => {
      if (!meshRef.current) return; // Exit if the mesh reference is not attached

      // Terminate any previously running animations
      if (animationRef.current) {
        animationRef.current.kill();
      }

      // Create a new GSAP timeline for animations
      const tl = gsap.timeline();
      animationRef.current = tl;

      // Add a pop-up scaling animation
      tl.fromTo(
        meshRef.current.scale,
        { x: 0, y: 0, z: 0 }, // Start with scale 0 (invisible)
        {
          x: 1,
          y: 1,
          z: 1, // Scale up to full size
          duration: 3, // Duration of the animation
          ease: "power4.out", // Easing function for a smooth effect
        }
      );

      // Add continuous rotation animation for all axes
      const fullRotation = Math.PI * 2; // Full rotation in radians (360 degrees)
      tl.to(
        meshRef.current.rotation,
        {
          x: `+=${rotationSpeedRef.current.x * fullRotation}`, // Apply X-axis rotation
          y: `+=${rotationSpeedRef.current.y * fullRotation}`, // Apply Y-axis rotation
          z: `+=${rotationSpeedRef.current.z * fullRotation}`, // Apply Z-axis rotation
          duration: 60, // Duration for one full rotation cycle
          ease: "linear", // Linear easing for consistent rotation speed
          repeat: -1, // Infinite repetition for continuous rotation
        },
        "<" // Start this animation concurrently with the scaling animation
      );
    };

    // Ensure the mesh is ready by using a slight delay
    const timeout = setTimeout(() => {
      startAnimation();
    }, 100); // 100ms delay to ensure `meshRef` is attached

    // Cleanup function to cancel the timeout and kill animations
    return () => {
      clearTimeout(timeout); // Clear the timeout to avoid unintended execution
      if (animationRef.current) {
        animationRef.current.kill(); // Terminate the GSAP timeline
        animationRef.current = null; // Reset the animation reference
      }
    };
  }, []); // Run this effect only once when the component mounts

  return {
    meshRef, // Expose the mesh reference for use in components
  };
};

export default useCMYCubeScene;
